.sd-product-spp {
  // Hide QTY Picker for Specific SKU
  &[data-sku-base-id='155807'],
  &[data-sku-base-id='155808'] {
    .elc-quantity-selector-button {
      display: none;
    }
    .elc-product-full-cta-wrapper {
      padding: 0;
    }
  }
  .elc-product-promo-message {
    @media #{$medium-up} {
      margin: 20px 0 10px;
    }
  }
}
