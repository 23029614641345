//Down price MPP for CA
a.selectBox.down-price-selectbox,
ul.down-price-selectbox {
  font-size: 10px;
  .product {
    &__price {
      &--non-sale,
      &--sale {
        font-size: 10px;
      }
    }
  }
}

a.selectBox.down-price-selectbox {
  padding: 0 0 20px 0;
}

a.selectBox.search-down-price-selectbox {
  width: auto;
}
