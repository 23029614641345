#cookie_popover_container {
  position: fixed;
  bottom: 0;
  background-color: #fff;
  color: white;
  display: none;
  padding: 20px 25px 20px 20px;
  width: 100%;
  border: 10px solid #000;
  z-index: 10000;
  @media #{$medium-up} {
    width: 295px;
    height: auto;
    right: 10px;
    bottom: 0;
    display: inline-block;
  }
  a {
    color: #040a2b;
    text-transform: none;
    text-decoration: underline;
  }
  .cookie_legal-language {
    font-size: 12px;
    line-height: 16px;
    color: #535353;
  }
  .cookie_legal-close_box {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 15px;
    height: 15px;
    cursor: pointer;
    background: url('/media/images/cookies/close_x.png');
  }
}
