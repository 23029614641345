/*
 * Desktop Store Locator
 */
$store-locator-nav-height: 45px;
$store-locator-sidebar-width: 330px;
$trans-mode-width: 71px;

// Important: Note the use of .device-pc
.section-node.device-pc {
  .store-locator {
    margin: 0 30px 70px;
    .store-locator__header {
      text-align: center;
    }
    .store-locator__title {
      @include headline--tertiary;
    }
    .error_messages li {
      margin-bottom: 13px;
    }
    .local-search__search-container .form-submit {
      width: 100%;
      height: 41px;
      line-height: 41px;
    }
    .store-locator__nav {
      border-bottom: solid 1px $color-light-gray;
      height: $store-locator-nav-height;
      line-height: $store-locator-nav-height;
      .store-locator__nav-item {
        @include text--short;
        cursor: pointer;
        display: inline-block;
        margin-left: 35px;
        position: relative;
        &.first {
          margin-left: 0;
        }
        &.active {
          @include text--bold;
          &:before {
            content: ' ';
            background-size: auto auto;
            background-position: 0 -11262px;
            height: 8px;
            width: 13px;
            position: absolute;
            top: $store-locator-nav-height - 2px;
            left: 50%;
            margin-left: -13px;
          }
        }
      }
    }
    .store-locator__sub-page {
      @include legacy-pie-clearfix;
    }

    // Local search:
    .local-search__action-links {
      text-align: right;
      padding: 25px 0 20px;
    }
    .local-search__action-link {
      display: inline-block;
      margin-left: 20px;
    }
    .local-search__search-container {
      float: left;
      width: $store-locator-sidebar-width;
      padding-right: 20px;
      .local-search__search-container-inner {
        border: solid 1px $color-light-gray;
      }
    }
    .local-search__map-container {
      margin-left: $store-locator-sidebar-width;
    }
    .local-search__options {
      @include clearfix;
    }
    .local-search__option {
      @include background(linear-gradient($color-white 54%, #eaeaea));
      @include text--short;
      float: left;
      width: 50%;
      height: 40px;
      line-height: 40px;
      padding: 0 20px;
      border-bottom: solid 1px $color-light-gray;
      cursor: pointer;
      &.local-search__option-directions {
        border-left: solid 1px $color-light-gray;
      }
      &.active {
        @include text--bold;
        border-bottom: none;
        background: $color-white;
      }
    }
    // Local search form:
    .local-search-form {
      padding: 17px 0 0;
      position: relative;
      .local-search-form__inner {
        padding: 0 9px;
      }
      .local-search-form__zip {
        @include input-placeholder {
          text-transform: uppercase;
        }
      }
      .local-search-form__city-state-wrapper,
      .local-search-form__zip-wrapper {
        @include clearfix;
        margin-bottom: 10px;
      }
      .local-search-form__zip,
      .local-search-form__or,
      .local-search-form__state,
      .local-search-form__city {
        width: 142px;
        display: block;
        float: left;
      }
      .local-search-form__state,
      .local-search-form__city {
        width: 142px !important;
      }
      .local-search-form__or,
      .local-search-form__city {
        float: right;
      }
      .local-search-form__or {
        line-height: 33px;
      }
      // Filters:
      .store-name-filters {
        background-color: $color-white;
        border: solid 1px $color-light-gray;
        display: none;
        margin: 0 9px;
        &.expanded {
          @include box-sizing('content-box');
          position: relative;
          width: 100%;
          margin: 0 0 0 -1px;
          border-color: $color-black;
          .store-name-filters__plus-minus {
            background-size: auto auto;
            background-position: 0 -4775px;
            height: 9px;
            width: 8px;
          }
        }
        .store-name-filters__inner {
          padding: 0 16px;
        }
        .store-name-filters__header {
          padding: 15px 0;
          text-align: center;
          cursor: pointer;
        }
        .store-name-filters__plus-minus {
          background-size: auto auto;
          background-position: 0 -7224px;
          height: 9px;
          width: 9px;
          display: inline-block;
        }
        .store-name-filters__dropdown {
          padding: 19px 0 28px;
          border-top: solid 1px #fafafa;
          display: none;
        }
        &.expanded .store-name-filters__dropdown {
          display: block;
        }
        .store-name-filters__list {
          margin: 0 0 0 14px;
        }
        .store-name-filters__item {
          margin: 10px 0;
        }
        .store-name-filters__clear {
          display: block;
          text-align: center;
          margin-top: 20px;
        }
      }
    }
    &.has-filters {
      .store-name-filters {
        display: block;
      }
      &.expanded-filters .store-results {
        margin-top: 29px;
      }
    }

    // Results:
    .store-results {
      margin-top: 29px;
    }
    .store-results-header {
      @include headline--quinary;
      margin: 26px 0 3px 12px;
    }
    .doors-results__inner {
      border-top: solid 1px $color-light-gray;
    }
    .door-row {
      @include clearfix;
      border-bottom: solid 1px $color-light-gray;
      padding: 18px 10px 15px;
      cursor: pointer;
      &.active {
        background-color: #f6f6f6;
      }
      .door-row__closest {
        @include text--bold;
        margin: 0 0 12px;
      }
      .door-row__directions-header {
        display: none;
      }
      .door-row__marker {
        float: left;
        width: 40px;
      }
      .door-row__info {
        padding-left: 40px;
        line-height: 1.5em;
      }
      .door-row__name {
        @include headline--quinary;
        margin: 0 0 5px;
      }
      .door-row__distance {
        float: right;
        font-size: 14px;
        margin-top: 5px;
      }
      .door-row__hours-link {
        text-decoration: underline;
        display: inline-block;
        margin-top: 5px;
      }
      .door-row__hours {
        display: none;
      }
      &:last-child,
      &:nth-child(4n) {
        border-bottom: none;
      }
    }
    &.show-all .door-row:nth-child(4n) {
      border-bottom: solid 1px $color-light-gray;
    }

    // Pager
    .doors-results__pager {
      border-top: solid 1px $color-light-gray;
      height: 52px;
      line-height: 52px;
      padding: 0 0 0 20px;
      .doors-results__pager-view-all {
        @include link--bold;
        display: inline-block;
        float: left;
        &.active {
          text-decoration: none;
          cursor: default;
        }
      }
      .doors-results__pager-pages {
        float: left;
        margin: 0 0 0 20px;
        padding: 0 0 0 20px;
        border-left: solid 1px $color-light-gray;
      }
      .doors-results__pager-page-link {
        float: left;
        text-decoration: underline;
        cursor: pointer;
        margin: 0 15px 0 0;
        &.active {
          text-decoration: none;
          cursor: default;
        }
      }
    }

    // Directions form:
    .directions-form {
      .error_messages {
        color: red;
        padding: 0 10px;
      }
    }
    .directions-form__inner {
      width: ($trans-mode-width * 4) + 2;
      margin: 0 auto;
      padding-bottom: 20px;
    }
    .directions-form__transportation-modes {
      @include clearfix;
      margin: 15px 0 20px;
      border: solid 1px $color-light-gray;
    }
    .directions-form__transportation-mode-link {
      @include hide-text;
      display: block;
      float: left;
      width: $trans-mode-width;
      height: 33px;
      background-color: #f0f0f2;
      background-repeat: no-repeat;
      border-left: solid 1px $color-light-gray;
      &:first-child {
        border-left: none;
      }
      &.directions-form__transportation-mode-link--driving {
        background-size: auto auto;
        background-position: 0 -11101px;
        &.active {
          background-size: auto auto;
          background-position: 0 -11159px;
        }
      }
      &.directions-form__transportation-mode-link--transit {
        background-size: auto auto;
        background-position: 0 -11353px;
        &.active {
          background-size: auto auto;
          background-position: 0 -11411px;
        }
      }
      &.directions-form__transportation-mode-link--walking {
        background-size: auto auto;
        background-position: 0 -11469px;
        &.active {
          background-size: auto auto;
          background-position: 0 -11527px;
        }
      }
      &.directions-form__transportation-mode-link--bicycling {
        background-size: auto auto;
        background-position: 0 -10927px;
        &.active {
          background-size: auto auto;
          background-position: 0 -10985px;
        }
      }
    }
    .directions-form__input-wrapper {
      padding: 0 0 0 34px;
      margin: 0 0 11px;
      &.directions-form__input-wrapper--a {
        background-size: auto auto;
        background-position: 0 -11295px;
      }
      &.directions-form__input-wrapper--b {
        background-size: auto auto;
        background-position: 0 -11043px;
      }
      .directions-form__input {
        width: 100%;
      }
    }
    .directions-form__submit {
      width: 100%;
    }

    // Directions results:
    .directions-form__results {
      @include text--long-copy;
      font-size: 14px;
      .adp-legal {
        font-size: 10px;
        text-align: right;
        margin: 0 10px 10px 0;
        line-height: normal;
      }
      .adp-placemark {
        padding-top: 12px;
        padding-bottom: 10px;
        td:first-child {
          padding-left: 30px;
          padding-right: 12px;
        }
        .adp-text {
          padding-right: 15px;
        }
      }
      .adp-directions {
        .adp-substep {
          width: 160px;
          max-width: 160px;
          padding-top: 12px;
          padding-bottom: 10px;
        }
        .adp-substep {
          &:first-child {
            padding-left: 15px;
          }
          &:nth-child(2) {
            font-weight: bold;
          }
        }
        .adp-distance {
          padding-right: 9px;
        }
      }
      .adp-agencies {
        padding: 0 15px;
      }
    }
    .directions-form__closest-store {
      border-top: solid 1px $color-light-gray;
      .door-row,
      .door-row__info,
      .door-row__marker {
        cursor: default;
      }
      .door-row__closest {
        display: none;
      }
      .door-row__directions-header {
        @include clearfix;
        display: block;
        .door-row__closest {
          display: block;
          float: left;
        }
        .door-row__change {
          float: right;
          line-height: 1em;
          text-decoration: underline;
        }
      }
    }

    // Maps:
    .local-search__map,
    .local-search__dir-map {
      height: 600px;
    }
    .location-info {
      border: none;
      width: 254px;
      font-size: 15px;
      font-family: $akzidenz;
      text-transform: none;
    }

    // International search:
    .intl-search {
      text-align: center;
      margin: 30px 0 0;
      .intl-search__copy {
        @include text--long-copy;
        font-size: 14px;
        letter-spacing: 0.05em;
        margin: 0 0 20px;
      }
      .intl-search__label {
        @include headline--quaternary;
        font-size: 40px;
        letter-spacing: -0.01em;
        margin: 0 0 20px;
      }
      .intl-search__menu {
        .menu {
          display: none;
        }
        .intl-search__menu-select {
          width: 246px !important;
          text-transform: uppercase;
        }
      }
    }

    // Display logic:
    .store-locator__loading-message,
    &.store-locator--loading .store-locator__sub-page.local-search,
    .local-search__search-container--directions .local-search-form,
    .directions-form,
    .store-locator__sub-page,
    &.store-locator--directions-map .local-search__map,
    .local-search__dir-map,
    .store-locator__sub-page.local-search.directions .local-search__map,
    .store-locator__sub-page.local-search .local-search__dir-map {
      display: none;
    }
    &.store-locator--loading .store-locator__loading-message,
    .local-search__search-container--directions .directions-form,
    &.store-locator--local-search .local-search,
    &.store-locator--intl-search .intl-search,
    &.store-locator--trip-planner .trip-planner,
    &.store-locator--directions-map .local-search__dir-map,
    .store-locator__sub-page.local-search .local-search__map,
    .store-locator__sub-page.local-search.directions .local-search__dir-map {
      display: block;
    }
  } // .store-locator

  .intl-search__menu-select-selectBox-dropdown-menu {
    text-transform: uppercase;
  }
  .store-locator-location-info {
    // InfoBubble clones this and appends to body to get the dimensions of
    // the outer bubble, so any layout-related stuff needs to be set outside
    // of .store-locator
    width: 256px;
    padding: 20px;
    line-height: 1.6em;
    border: solid 1px $color-light-gray;
    .location-info__door-name {
      @include headline--quinary;
      margin-bottom: 10px;
    }
    .location-info__links {
      margin-top: 15px;
    }
    .location-info__link-item {
      display: inline-block;
      margin-right: 18px;
      &:last-child {
        margin-right: 0;
      }
    }
    .location-info__link-item {
      text-decoration: underline;
    }
    .location-info__hours {
      display: none;
    }
    &.show-hours .location-info__hours {
      display: block;
    }
  }

  // Services
  $store-locator-promo-width: 224px;
  $store-locator-promo-gutter: 62px;
  .find-a-store-page {
    .promo-unit__headline {
      text-align: center;
      margin: 0 30px 40px;
    }
    .promo-unit--threecol {
      @include clearfix;
      width: ($store-locator-promo-width * 3) + ($store-locator-promo-gutter * 2);
      margin: 0 auto 80px;
      .promo-unit {
        float: left;
        width: $store-locator-promo-width;
        margin-right: $store-locator-promo-gutter;
        &:last-child {
          margin-right: 0;
        }
      }
      .promo-unit__headline {
        @include headline--quinary;
        margin: 38px 0 15px;
      }
    }
  }
} //.device-pc
