// Everything is nice and centered except the registration form, which randomly
// has stuff breaking the grid. So we can't just set the width and margin: auto,
// we have to do this:

.sign-in-component {
  // Registration confirmation
  .sign-in-component__confirm--registration {
    @media #{$medium-up} {
      .sign-in-component__confirm-registration-field-wrapper {
        float: none;
        text-align: center;
        width: 100%;
      }
    }
    .sign-in-component__sms-opt-in {
      display: none;
    }
  }
}

@media #{$small-only} {
  .sign-in-component__confirm--registration .sign-in-component__sms-opt-in {
    display: none !important;
  }
}
