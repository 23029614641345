//* In Page Footer */

.footer-main-menu {
  @media #{$small-only} {
    .menu .expanded {
      a , .careers {
        padding-#{$ldirection}: 25px;
      }
      &.last {
        border-bottom: $color-medium-navy;
        p {
          font-size: 15px;
          font-family: $akzidenz;
          margin-bottom: 0;
          a {
            font-size: 15px;
            font-family: $akzidenz;
            line-height: 1.8;
            height: auto;
            border: 0;
            letter-spacing: 0.05em;
            text-decoration: underline;
          }
        }
      }
    }
  }
}

.footer-forms-location-sms-email-signup-elc-nodeblock {
  @media #{$medium-up} {
    .footer-signup-email {
      .form-text {
        float: #{$ldirection};
        width: 70%;
      }
      .form-submit {
        float: none;
      }
    }
  }
}

.page-footer {
  .email-signup {
    .form-submit {
      float: none;
    }
  }
}
