.elc-product-full {
  .sold-out,
  .coming-soon {
    .elc-add-to-bag-button {
      color: $color-dark-gray;
      &:hover {
        background: $color-dark-blue;
      }
    }
  }
  .temporarily-out-of-stock,
  .sold-out,
  .coming-soon {
    .elc-add-to-bag-button {
      letter-spacing: 0.15em;
    }
  }
}

.sd-product-spp {
  .coming-soon {
    .elc-add-to-bag-button {
      width: 148px;
    }
  }
  .sold-out {
    .elc-add-to-bag-button {
      width: 115px;
    }
  }
  .elc-sticky-container {
    .elc-product-full-sticky-menu {
      .sold-out,
      .coming-soon,
      .temporarily-out-of-stock {
        .elc-add-to-bag-button {
          letter-spacing: 0.08em;
        }
      }
      .sold-out,
      .coming-soon {
        .elc-add-to-bag-button {
          color: $color-dark-gray;
        }
      }
    }
  }
}

.sd-renutriv-spp-formatter {
  .elc-product-full {
    .elc-right-column-section {
      .elc-product-full-cta-wrapper {
        .sold-out,
        .coming-soon,
        .temporarily-out-of-stock {
          .elc-add-to-bag-button {
            font-size: 14px;
            font-weight: 500;
            line-height: get-line-height(14px, 17px);
            letter-spacing: 0.05em;
          }
        }
        .sold-out,
        .coming-soon {
          .elc-add-to-bag-button {
            background-color: $color-dark-blue;
            color: $color-dark-gray;
          }
        }
        .temporarily-out-of-stock,
        .coming-soon {
          .elc-add-to-bag-button {
            width: 131px;
          }
        }
        .sold-out {
          .elc-add-to-bag-button {
            width: 104px;
          }
        }
      }
    }
  }
  .elc-sticky-container {
    .elc-product-full-sticky-menu {
      .sold-out,
      .coming-soon,
      .temporarily-out-of-stock {
        .elc-add-to-bag-button {
          font-size: 14px;
          font-weight: 500;
          line-height: get-line-height(14px, 17px);
          letter-spacing: 0.05em;
        }
      }
      .sold-out,
      .coming-soon {
        .elc-add-to-bag-button {
          background-color: $color-dark-blue;
          color: $color-dark-gray;
        }
      }
    }
  }
}

.sd-aerin-spp-formatter {
  .elc-product-full {
    .elc-right-column-section {
      .elc-product-full-cta-wrapper {
        .temporarily-out-of-stock,
        .sold-out,
        .coming-soon {
          .elc-add-to-bag-button {
            line-height: get-line-height(12px, 20px);
            letter-spacing: 0.05em;
          }
        }
        .sold-out,
        .coming-soon {
          .elc-add-to-bag-button {
            color: $color-gray--aerin;
          }
        }
        .sold-out {
          .elc-add-to-bag-button {
            width: 101px;
          }
        }
        .coming-soon {
          .elc-add-to-bag-button {
            width: 135px;
          }
        }
        .temporarily-out-of-stock {
          .elc-add-to-bag-button {
            width: 117px;
          }
        }
      }
    }
  }
  .elc-sticky-container {
    .elc-product-full-sticky-menu {
      .sold-out,
      .coming-soon,
      .temporarily-out-of-stock {
        .elc-add-to-bag-button {
          font-size: 12px;
          font-weight: 500;
          line-height: get-line-height(12px, 14px);
          letter-spacing: 0.05em;
        }
      }
      .sold-out,
      .coming-soon {
        background-color: $color-dark-blue;
        color: $color-dark-gray;
      }
    }
  }
}
