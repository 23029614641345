///
/// @file drupal_modules/esteelauder_common/_franchise_bestfor_v1.scss
///

.beauty-tip-foundation-elc-nodeblock,
.how-to-use-instant-beauty-routine-elc-nodeblock {
  a.translation-link {
    display: none;
  }
}
