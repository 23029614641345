// moving scss from directories:
// components
// layout

//
// scss/layout/_sticky_footer.scss
//

.page-sticky-footer {
  .page-sticky-footer__inner {
    z-index: 4; // was 1, clearing loyalty my feed
  }
  .page-sticky-footer__left .node-elc-nodeblock,
  .page-sticky-footer__right .menu > li {
    @media #{$medium-up} {
      font-size: 14px;
    }
  }
  .page-sticky-footer__right {
    .page-sticky-footer__right__inner {
      @media #{$medium-up} {
        float: right;
      }
      .menu > li {
        @media #{$medium-up} {
          a b {
            // temp fix, remove <b> from cms
            font-weight: normal;
          }
        }
        &.first {
          border-left: none;
          padding-left: 0;
          @media #{$medium-up} {
            border-left: 1px solid $color-medium-gray;
            padding-left: 14px;
          }
        }
        &.last {
          // disabling, last is not always language toggle
          //display: none; // update via cms after launch
          padding-right: 0px;
          @media #{$medium-up} {
            padding-right: 14px;
          }
        }
      }
    }
    .page-sticky-footer__right__offers {
      padding-right: 14px;
    }
  }
}

// _beauty_chat
.beauty-chat-drawer .drawer-formatter__trigger {
  text-transform: uppercase;
}

//
// scss/components/header/_page_navigation.scss
//
.page-navigation {
  .menu-reference {
    &.menu-reference--loyalty,
    &.menu-reference--signin,
    &.menu-reference--account {
      @media #{$medium-up} {
        display: none;
      }
    }
  }
}

.menu-reference {
  .page-navigation & {
    &:last-child {
      margin-bottom: 20px; //clear sticky footer
      @media #{$medium-up} {
        margin-bottom: 0;
      }
    }
  }
}

.elc-user-state-anonymous .page-navigation .menu-reference--loyalty.js-active .level-1 {
  background-size: auto auto;
  background-position: 100% -6835px;
}

.level-2 {
  .menu-reference--loyalty & {
    padding: 15px 25px;
  }
  .elc-user-state-anonymous .menu-reference--loyalty & {
    display: none;
  }
}

// menu states
.elc-user-state-anonymous .page-navigation {
  .menu-reference--account {
    display: none;
  }
}

.elc-user-state-logged-in .page-navigation {
  .menu-reference--signin {
    display: none;
  }
}

//
// scss/components/header/_page_utilities.scss
//

//.page-utilities-elc-nodeblock,
.page-utilities-loyalty-elc-nodeblock {
  float: left;
}

.page-utilities__account-button {
  cursor: pointer;
  padding: 0 20px 0 10px;
  @media #{$small-only} {
    padding: 0;
  }
}

.page-utilities__account-text {
  .elc-user-state-logged-in & {
    font-size: 12px;
    font-weight: normal;
    text-transform: none;
    @media #{$small-only} {
      letter-spacing: 0;
      font-size: 11px;
      padding-top: 2px;
      .js-is_signed_in {
        display: none;
      }
    }
  }
}

.user-login-state {
  @media #{$medium-up} {
    .page-utilities__loyalty & {
      position: static;
      min-width: 0;
    }
  }
}

// loyalty
.user-loyalty-state {
  @media #{$medium-up} {
    display: block;
    position: absolute;
    font-size: 12px;
    font-weight: normal;
    text-align: right;
    right: 20px;
    min-width: 375px;
    @media #{$large-down} {
      @media #{$portrait} {
        top: 38px;
      }
    }
    #{$alt} & {
      color: $color-white;
    }
    .user-logged-out,
    .user-logged-in {
      display: none;
      &__link {
        margin-left: 9px;
        color: $color-navy;
        font-weight: normal;
        text-decoration: none;
        .js-loyalty-points-value {
          font-weight: bold;
        }
        #{$alt} & {
          color: $color-white;
        }
      }
    }
  }
  @media #{$large-up} {
    bottom: 0;
  }
  @media #{$medium-only} {
    right: 100px;
  }
  @media #{$large-only} {
    right: 10px;
  }
}

.page-utilities__loyalty {
  .user-greeting {
    margin-left: 9px;
    color: $color-navy;
    font-weight: normal;
    text-transform: none;
    text-decoration: none;
    #{$alt} & {
      color: $color-white;
    }
  }
  .sign-out-link {
    margin-left: 7px;
    color: $color-darker-gray;
    font-weight: normal;
    text-transform: none;
    text-decoration: none;
    #{$alt} & {
      color: $color-white;
    }
  }
}

// drupal_modules/esteelauder_global/_special_offers.scss
.special-offers-drawer .drawer-formatter__trigger {
  font-weight: normal;
  font-size: 14px;
}

// drupal_modules/esteelauder_common/_drawer_formatter.scss
.drawer-formatter__close {
  z-index: 3; //clear z-index for loyalty my feed
}

.account-mobile-landing-page {
  .account-landing-profile__link {
    height: 45px;
    line-height: 45px;
    width: 100%;
  }
}

.footer-legal {
  @media #{$medium-up} {
    .menu li:nth-child(3n + 3) {
      padding-right: 50px;
    }
  }
}

#colorbox.email_signup_sucess_popup {
  background: $color-navy !important;
  opacity: 0.9 !important;
  position: absolute !important;
  @media #{$medium-up} {
    background: none;
  }
  #cboxContent {
    background-color: transparent;
    height: 100% !important;
    overflow: visible;
    #cboxClose {
      background-image: url(/media/images/global/sprites-s38a9885fce.png);
    }
  }
  #cboxWrapper {
    height: 100% !important;
  }
  #cboxLoadedContent {
    color: $color-white;
    overflow: visible !important;
  }
  .email_signup_success_container {
    .email_signup_success_content {
      font-family: $akzidenz;
      font-size: 14px;
      text-transform: uppercase;
      @media #{$medium-up} {
        font-size: 15px;
      }
    }
    .email-signup__success-text,
    .email_signup_success_join_loyalty {
      font-size: 12px;
      display: block;
      font-weight: bold;
      color: #9eafff;
      margin: 20px 0;
      text-decoration: underline;
      text-align: left;
      text-transform: uppercase;
      cursor: pointer;
    }
    .email-signup__success_terms_condition {
      @media #{$medium-up} {
        font-size: 13px;
      }
      a {
        text-decoration: underline;
        color: $color-white;
      }
    }
    .email-signup__success-header {
      font-weight: bold;
      margin: 10px 0;
      font-size: 45px;
      letter-spacing: -0.02em;
      text-align: left;
      @media #{$medium-up} {
        font-size: 38px;
        letter-spacing: -0.04em;
        margin: 20px 0;
      }
    }
    .email_signup_success_join_loyalty_offer {
      font-size: 14px;
      line-height: 20px;
      text-transform: uppercase;
      @media #{$medium-up} {
        font-size: 18px;
      }
      span {
        @media #{$medium-up} {
          display: block;
        }
      }
    }
  }
}

.estee_elist_loyalty_program {
  @media #{$medium-up} {
    margin: 10px 0;
  }
}

.loyalty-landing-menu {
  &__item {
    &.in-store-activity-link {
      display: none;
    }
  }
}
//Loyalty Welcome overlay changes
.device-mobile {
  #cboxOverlay {
    &.loyalty-offer-overlay {
      background: transparent;
      height: 610px;
    }
  }
  #colorbox {
    &:not(.overlay--interstitial):not(.overlay--auto-size) {
      height: 610px !important;
      &.colorbox__spp-video {
        #cboxClose {
          background-position: -26px -2px;
          height: 22px;
          width: 22px;
        }
      }
    }
  }
  .loyalty_popover {
    padding: 20px 0 40px;
    &__content {
      padding: 15px 0;
    }
  }
}

.loyalty-offer-overlay {
  .loyalty_popover {
    &.show_second_language {
      .loyalty_popover__signup {
        overflow-y: hidden;
      }
    }
    &__header {
      padding-top: 10px;
      @media #{$medium-up} {
        padding-top: 0;
      }
    }
  }
}

#loyalty__content {
  .e-list_not_signed {
    .e-list_not_signed_wrapper {
      @media #{$medium-up} {
        height: auto;
        background-size: cover;
      }
    }
  }
}

[lang='fr'] {
  #loyalty__content {
    .e-list_not_signed {
      .e-list_not_signed_wrapper {
        .e-list_not_signed-left {
          @media #{$medium-up} {
            width: 50%;
          }
          @media #{$xlarge-up} {
            width: 44%;
          }
        }
        .e-list_not_signed-right {
          @media #{$medium-up} {
            width: 50%;
          }
          @media #{$xlarge-up} {
            width: 44%;
          }
        }
      }
    }
  }
}
