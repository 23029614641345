/*
 * Mobile Store Locator
 */

// Important: Note the use of .device-mobile
.section-store-locator.device-mobile {
  .store-locator {
    margin: 0 0 40px 0;
    padding-top: 25px;
    .store-locator__header {
      text-align: center;
    }
    .store-locator__title {
      @include headline--tertiary;
      margin-bottom: 17px;
    }
    .error_messages li {
      margin-bottom: 13px;
    }
    .local-search__search-container .form-submit {
      width: 100%;
      height: 41px;
      line-height: 41px;
    }
    .store-locator__nav {
      @include clearfix;
      border-top: 1px solid #ccc;
      .store-locator__nav-item {
        @include background(linear-gradient($color-white 54%, #eaeaea));
        @include text--short;
        float: left;
        width: 100%;
        height: 40px;
        line-height: 40px;
        padding: 0 20px;
        border-bottom: solid 1px $color-light-gray;
        border-right: solid 1px $color-light-gray;
        cursor: pointer;
        white-space: nowrap;
        &.last {
          border-#{$rdirection}: 0;
          display: none;
        }
        &.active {
          @include text--bold;
          border-bottom: none;
          background: $color-white;
        }
      }
    }
    .store-locator__sub-page {
      @include legacy-pie-clearfix;
    }

    // Local search:
    .local-search__action-links {
    }
    .local-search__action-link {
      display: inline;
    }
    .local-search__search-container {
      .local-search__search-container-inner {
      }
    }
    .local-search__map-container {
      width: 100%;
      border-top: solid 1px $color-light-gray;
      border-bottom: solid 1px $color-light-gray;
      margin-bottom: 20px;
    }
    .local-search__options {
      @include clearfix;
      display: none;
    }
    .local-search__option {
      @include background(linear-gradient($color-white 54%, #eaeaea));
      @include text--short;
      float: left;
      width: 50%;
      height: 40px;
      line-height: 40px;
      padding: 0 20px;
      border-bottom: solid 1px $color-light-gray;
      cursor: pointer;
      &.local-search__option-directions {
        border-left: solid 1px $color-light-gray;
      }
      &.active {
        @include text--bold;
        border-bottom: none;
        background: $color-white;
      }
    }
    // Local search form:
    .local-search-form {
      padding: 15px 0 0;
      position: relative;
      .local-search-form__inner {
        padding: 0 $mobile-outer-gutter 25px $mobile-outer-gutter;
        border-bottom: solid 1px $color-light-gray;
        margin: 0 0 20px 0;
      }
      .local-search-form-header {
        @include headline--quinary;
        margin: 0 0 20px 0;
      }
      .local-search-form__zip {
        @include input-placeholder {
          text-transform: uppercase;
        }
      }
      .local-search-form__city-state-wrapper,
      .local-search-form__zip-wrapper {
        @include clearfix;
      }
      .local-search-form__zip,
      .local-search-form__or,
      .local-search-form__state,
      .local-search-form__city {
        width: 100%;
        display: block;
      }
      .local-search-form__state,
      .local-search-form__city {
        width: 100% !important;
        margin-bottom: 10px;
        padding-left: 10px;
      }
      .local-search-form__or {
        @include text--bold;
        padding: 8px 0 7px 0;
        text-transform: uppercase;
      }
      //Summary
      .summary_results {
        margin: 0 25px 10px;
      }
      // Filters:
      .store-name-filters {
        background-color: $color-white;
        border: solid 1px $color-light-gray;
        display: none;
        margin: 0 $mobile-outer-gutter 20px $mobile-outer-gutter;
        &.expanded {
          @include box-sizing('content-box');
          width: 100%;
          margin: 0 0 0 -1px;
          border-color: $color-black;
          .store-name-filters__plus-minus {
            background-size: auto auto;
            background-position: 0 -4775px;
            height: 9px;
            width: 8px;
          }
        }
        .store-name-filters__inner {
          padding: 0;
        }
        .store-name-filters__header {
          padding: 15px 0;
          text-align: center;
          cursor: pointer;
        }
        .store-name-filters__plus-minus {
          background-size: auto auto;
          background-position: 0 -7224px;
          height: 9px;
          width: 9px;
          display: inline-block;
        }
        .store-name-filters__dropdown {
          padding: 0 $mobile-outer-gutter 28px $mobile-outer-gutter;
          border-top: solid 1px #fafafa;
          display: none;
        }
        &.expanded .store-name-filters__dropdown {
          display: block;
        }
        .store-name-filters__list {
          padding: 0;
          margin: 0 0 20px 0;
        }
        .store-name-filters__item {
          margin: 15px 0;
          label {
            @include text--bold;
          }
        }
        .store-name-filters__clear {
          display: block;
          text-align: center;
          margin-top: 20px;
        }
      }
    }
    &.has-filters {
      .store-name-filters {
        display: block;
      }
      &.expanded-filters .store-results {
        margin-top: 0;
      }
    }
    .store-results-header-wrapper {
      padding: 0 $mobile-outer-gutter;
      margin: 0 0 23px 0;
    }

    // Results:
    .store-results {
      margin-top: 20px;
    }
    .store-results-header {
      @include headline--quinary;
      margin: 0 0 7px 0;
    }
    .doors-results__inner {
      border-top: solid 1px $color-light-gray;
      margin-bottom: 30px;
    }
    .door-row {
      @include clearfix;
      border-bottom: solid 1px $color-light-gray;
      padding: 18px $mobile-outer-gutter 15px $mobile-outer-gutter;
      cursor: pointer;
      &.active {
        background-color: #f6f6f6;
      }
      .door-row__closest {
        @include text--bold;
        margin: 0 0 12px;
      }
      .door-row__marker {
        float: left;
        width: 40px;
      }
      .door-row__info {
        padding-left: 40px;
        line-height: 1.5em;
      }
      .door-row__name {
        @include headline--quinary;
        margin: 0 0 10px;
        line-height: 23px;
      }
      .door-row__address {
        line-height: 17px;
        @include text--short;
      }
      .door-row__phone {
        line-height: 17px;
        @include text--short;
        text-decoration: underline;
      }
      .door-row__distance {
        float: right;
        margin-top: 0;
        @include text--tiny;
        text-transform: none;
      }
      .door-row__hours-link {
        line-height: 17px;
        @include text--short;
        text-decoration: underline;
        display: inline-block;
        margin-top: 5px;
      }
      .door-row__hours {
        display: none;
      }
      .door-row__get-directions {
        margin: 20px 0 10px 0;
        .door-row__get-directions-link {
          cursor: pointer;
          width: 100%;
        }
      }
    }
    &.show-all .door-row:nth-child(4n) {
      border-bottom: solid 1px $color-light-gray;
    }

    // Pager
    .doors-results__pager {
      text-align: center;
      .doors-results__pager-pages {
        width: 100%;
        text-align: center;
        margin-bottom: 20px;
      }
      .doors-results__pager-page-link {
        text-decoration: none;
        display: inline;
        cursor: pointer;
        padding: 0 13px;
        &.active {
          font-weight: 700;
          cursor: default;
        }
      }
      .previous.none,
      .next.none {
        opacity: 0.3;
      }
    }

    // Maps:
    .local-search__map,
    .local-search__dir-map {
      height: 320px;
    }
    .location-info {
      border: none;
      width: 254px;
      font-size: 15px;
      font-family: $akzidenz;
      text-transform: none;
    }

    // International search:
    .intl-search {
      text-align: center;
      margin: 20px 0 0;
      .intl-search__copy {
        @include text--long-copy;
        font-size: 14px;
        letter-spacing: 0.05em;
        margin: 0 0 20px;
      }
      .intl-search__label {
        @include headline--quaternary;
        letter-spacing: -0.01em;
        margin: 0 0 15px;
      }
      .intl-search__menu {
        .menu {
          display: none;
        }
        .intl-search__menu-select {
          width: 246px !important;
          text-transform: uppercase;
        }
      }
    }

    // Display logic:
    .store-locator__loading-message,
    &.store-locator--loading .store-locator__sub-page.local-search,
    .local-search__search-container--directions .local-search-form,
    .directions-form,
    .store-locator__sub-page,
    &.store-locator--directions-map .local-search__map,
    .local-search__dir-map {
      display: none;
    }
    &.store-locator--loading .store-locator__loading-message,
    .local-search__search-container--directions .directions-form,
    &.store-locator--local-search .local-search,
    &.store-locator--intl-search .intl-search,
    &.store-locator--trip-planner .trip-planner,
    &.store-locator--directions-map .local-search__dir-map {
      display: block;
    }
    .store-locator__loading-message {
      padding: 0 $mobile-outer-gutter;
    }
    .intl-search__menu-select-selectBox-dropdown-menu {
      text-transform: uppercase;
    }
  } //.store-locator

  .store-locator-location-info {
    // InfoBubble clones this and appends to body to get the dimensions of
    // the outer bubble, so any layout-related stuff needs to be set outside
    // of .store-locator
    width: 256px;
    padding: 20px;
    line-height: 1.6em;
    border: solid 1px $color-light-gray;
    .location-info__door-name {
      @include headline--quinary;
      margin-bottom: 10px;
      line-height: 23px;
    }
    .location-info__links {
      display: none;
      margin-top: 15px;
    }
    .location-info__link-item {
      display: inline-block;
      margin-right: 18px;
      &:last-child {
        margin-right: 0;
      }
    }
    .location-info__link-item {
      text-decoration: underline;
    }
    .location-info__hours {
      display: none;
    }
    &.show-hours .location-info__hours {
      display: block;
    }
  }
  .promo-unit--threecol {
    border-top: solid 1px $color-light-gray;
  }
} //.device-mobile
